












































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import { mdiArrowUp } from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";

@Component({
    components: {
        BackButton,
    },
    metaInfo() {
        const title = "What is the DStv Price Lock Feature?";
        const description =
            "This article explains what is the DStv price lock and how it affects your DStv subscription prices.";
        return {
            title,
            meta: [
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class DstvPackageChecker extends Vue {
    upIcon: string = mdiArrowUp;

    percentage(oldPrice: number, newPrice: number) {
        return (
            (
                ((newPrice - oldPrice) / parseFloat(oldPrice.toString())) *
                100
            ).toFixed(0) + "%"
        );
    }

    mounted(): void {
        addAnalyticsEvent("price_lock_banner_clicked");
    }
}
